import React from 'react';
import { BaseProps, getClassName } from './../../utils';
import './text.style.scss';

export enum TextClasses {
  Base = 'puf_text',
}

export enum TextTheme {
  Matte = 'matte',
  Atlantic = 'atlantic',
  Charcoal = 'charcoal',
  Snowball = 'snowball',
  Sky = 'sky',
}

export enum TextStyle {
  Heading = 'heading',
  Subheading = 'subheading',
  Body = 'body',
  Optimized = 'optimized',
}

export interface TextProps extends BaseProps {
  theme?: TextTheme;
  style?: TextStyle;
}

export const Text = (props: TextProps): JSX.Element => {
  const { children, className, style = TextStyle.Body, theme = TextTheme.Matte } = props;

  const textClassName = getClassName(TextClasses.Base, [
    {
      condition: !!className,
      trueClassName: className,
    },
    {
      condition: !!style,
      trueClassName: `${TextClasses.Base}--${style}`,
    },
    {
      condition: !!theme,
      trueClassName: `${TextClasses.Base}--${theme}`,
    },
  ]);

  const textProps = {
    className: textClassName,
  };

  switch (style) {
    case TextStyle.Optimized:
      return <h1 {...textProps}>{children}</h1>;
    case TextStyle.Heading:
      return <h2 {...textProps}>{children}</h2>;
    case TextStyle.Subheading:
      return <h3 {...textProps}>{children}</h3>;
    case TextStyle.Body:
    default:
      return <p {...textProps}>{children}</p>;
  }
};
