import React, { useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BaseProps, getClassName, MenuItems, scrollToTop, scrollToView } from '../../utils';
import { Logo } from '../logo/logo.component';
import { Hamburger } from '../menu';
import './toolbar.style.scss';

export enum ToolbarClasses {
  Base = 'toolbar',
  Menu = 'toolbar_menu',
  MenuItems = 'toolbar_menu_items',
  Hamburger = 'toolbar_hamburger',
  Dropdown = 'toolbar_dropdown',
  DropdownItems = 'toolbar_dropdown_items',
  SelectedMenuItem = 'toolbar_menu_items--selected',
  Logo = 'toolbar_logo',
}

export type ToolbarProps = BaseProps;

export const Toolbar = (): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const menuItemsText: string[] = useMemo(() => Object.values(MenuItems), []);
  const menuItems: JSX.Element[] = useMemo(() => {
    return menuItemsText.map((label, index) => {
      const labelClass = getClassName(ToolbarClasses.MenuItems, [
        {
          condition: index === selectedIndex,
          trueClassName: ToolbarClasses.SelectedMenuItem,
        },
      ]);

      const onClick = () => {
        scrollToView(label as MenuItems);
        setSelectedIndex(index);
      };

      return (
        <span className={labelClass} key={`menu-${label}`} onClick={onClick}>
          {label}
        </span>
      );
    });
  }, [selectedIndex, menuItemsText, setSelectedIndex]);

  const onHomeClick = useCallback(() => {
    setSelectedIndex(-1);
    scrollToTop();
  }, [setSelectedIndex]);

  const menu: JSX.Element = useMemo(() => {
    const menuClass = isMobile ? ToolbarClasses.Hamburger : ToolbarClasses.Menu;
    return (
      <div className={menuClass}>
        {isMobile ? <Hamburger {...{ menuItems: menuItemsText }} /> : menuItems}
      </div>
    );
  }, [menuItems, menuItemsText]);

  return (
    <div className={ToolbarClasses.Base}>
      <Logo className={ToolbarClasses.Logo} onClick={onHomeClick} />
      {menu}
    </div>
  );
};
