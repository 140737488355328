import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HamburgerMenu, { HamburgerMenuProps } from 'react-hamburger-menu';
import { BaseProps, getClassName, MenuItems, scrollToView } from './../../../utils';

import './hamburger.style.scss';

export interface HamburgerProps extends BaseProps {
  menuItems: string[];
  width?: number;
  height?: number;
}

export enum HamburgerClasses {
  Base = 'puf_hamburger',
  Icon = 'puf_hamburger_icon',
  Menu = 'puf_hamburger_menu',
  MenuItems = 'puf_hamburger_menu_items',
}

const BURGER_WIDTH = 15;
const BURGER_HEIGHT = 12;

export const Hamburger = (props: HamburgerProps): JSX.Element => {
  const { className, menuItems, width = BURGER_WIDTH, height = BURGER_HEIGHT } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const burgerClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const burgerClosed = () => setAnchorEl(null);

  const burgerProps: HamburgerMenuProps = {
    width,
    height,
    isOpen: menuOpen,
    menuClicked: () => {
      return null;
    },
    color: 'black',
  };

  const burgerClass = getClassName(HamburgerClasses.Base, [
    {
      condition: !!className,
      trueClassName: className,
    },
  ]);

  const onClick = (item: MenuItems) => {
    scrollToView(item);
    burgerClosed();
  };

  return (
    <div className={burgerClass}>
      <IconButton className={HamburgerClasses.Icon} onClick={burgerClicked}>
        <HamburgerMenu {...burgerProps} />
      </IconButton>
      <Menu
        PopoverClasses={{ paper: HamburgerClasses.Menu }}
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={burgerClosed}
      >
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              className={HamburgerClasses.MenuItems}
              key={`hamburger_${item}_${index}`}
              onClick={() => onClick(item as MenuItems)}
            >
              {item}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
