import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { getPublicRoute, MenuItems, openInNewTab, ScreenWidths, ViewIds } from './../../utils';
import { Card, CardLayout, CardProps } from './../../components/card/card.component';
import { TextTheme } from './../../components/text/text.component';

import './starting.style.scss';

export enum StartingClasses {
  Base = 'starting',
  Heading = 'starting_heading',
  Steps = 'starting_steps',
  StepCard = 'starting_steps_card',
  WalletLink = 'starting_wallet',
}

const cardTheme: Partial<CardProps> = {
  titleTheme: TextTheme.Matte,
  subtitleTheme: TextTheme.Atlantic,
  bodyTheme: TextTheme.Charcoal,
};

const yoroiiProps = {
  className: StartingClasses.WalletLink,
  onClick: () => openInNewTab('https://yoroi-wallet.com/'),
};

const daedalusProps = {
  className: StartingClasses.WalletLink,
  onClick: () => openInNewTab('https://daedaluswallet.io/'),
};

const headingCardContent: CardProps = {
  title: 'Getting Started',
  children: (
    <>
      To stake Cardano, you will need to install a staking wallet. Two of the most popular wallets
      for staking are <a {...yoroiiProps}>Yoroi</a> and <a {...daedalusProps}>Daedalus</a>. We
      suggest using Yoroi since it&apos;s available on mobile and takes minutes to set up.
    </>
  ),
  className: StartingClasses.Heading,
  ...cardTheme,
};

const startingCardContent: CardProps[] = [
  {
    title: '1. Install',
    subtitle: 'Create Yoroi Wallet',
    children: (
      <>
        Install <a {...yoroiiProps}>Yoroi Wallet</a> and follow their instructions to create a new
        wallet. Don&apos;t forget to save your recovery phrase!
      </>
    ),
    src: `${getPublicRoute()}/assets/install.png`,
    className: StartingClasses.StepCard,
    ...cardTheme,
  },
  {
    title: '2. Transfer',
    subtitle: 'Move ADA to Yoroi',
    children: <>Once you have created a wallet, transfer your ADA from an existing wallet.</>,
    src: `${getPublicRoute()}/assets/transfer.png`,
    className: StartingClasses.StepCard,
    ...cardTheme,
  },
  {
    title: '3. Stake!',
    subtitle: 'Delegate with PUFF',
    children: (
      <>
        Navigate to the <i>Delegate</i> tab and search for &quot;PUFF&quot;. Select our pool and
        click <b>Delegate</b>!
      </>
    ),
    src: `${getPublicRoute()}/assets/stake.png`,
    className: StartingClasses.StepCard,
    ...cardTheme,
  },
];

export const Starting = (): JSX.Element => {
  const useHybrid = useMediaQuery({ maxWidth: ScreenWidths.Foldable });
  const useRow = useMediaQuery({ minWidth: ScreenWidths.Foldable, maxWidth: ScreenWidths.Laptop });
  const layout = useHybrid ? CardLayout.Hybrid : useRow ? CardLayout.Row : CardLayout.Column;

  return (
    <div id={ViewIds[MenuItems.GetStarted]} className={StartingClasses.Base}>
      <Card {...headingCardContent} />
      <div className={StartingClasses.Steps}>
        {startingCardContent.map((card, index) => {
          card.layout = layout;
          return <Card key={`starting_card_${index}`} {...card} />;
        })}
      </div>
    </div>
  );
};
