import React, { ReactNode, useCallback, useState } from 'react';
import './expandable.style.scss';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { BaseProps, getClassName } from '../../utils';

export enum ExpandableClasses {
  Container = 'puf_expandable_container',
  ContainerExpanded = 'puf_expandable_container_expanded',
  Base = 'puf_expandable_base',
  Title = 'puf_expandable_title',
  TitleText = 'puf_expandable_title_text',
  BodyText = 'puf_expandable_body_text',
}

export interface ExpandableProps extends BaseProps {
  initialExpandState?: boolean;
  title: string;
  children?: ReactNode;
  icon?: JSX.Element;
}

export const Expandable = (props: ExpandableProps): JSX.Element => {
  const {
    initialExpandState = false,
    title,
    children = 'Default body text',
    icon = <ArrowForwardIosSharpIcon />,
  } = props;

  const [expanded, setExpanded] = useState<boolean>(initialExpandState);

  const handleChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const expandableClassName = getClassName(ExpandableClasses.Container, [
    { condition: expanded, trueClassName: ExpandableClasses.ContainerExpanded },
  ]);

  return (
    <div className={expandableClassName}>
      <Accordion className={ExpandableClasses.Base} expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          className={ExpandableClasses.Title}
          expandIcon={icon}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography className={ExpandableClasses.TitleText}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={ExpandableClasses.BodyText}>{children}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
