import React from 'react';
import { BaseProps, getClassName } from './../../utils';
import Button from '@mui/material/Button';
import './button.style.scss';

export enum ButtonClasses {
  Base = 'puf_button',
}

export enum ButtonTheme {
  Atlantic = 'atlantic',
  Sunrise = 'sunrise',
}

export enum ButtonVariant {
  Text = 'text',
  Contained = 'contained',
  Outlined = 'outlined',
}

export interface ButtonProps extends BaseProps {
  label?: string;
  theme?: ButtonTheme;
  variant?: ButtonVariant;
  onClick?: () => void;
}

export const PuffinButton = (props: ButtonProps): JSX.Element => {
  const {
    label,
    theme = ButtonTheme.Atlantic,
    variant = ButtonVariant.Contained,
    onClick = () => null,
  } = props;
  const buttonClass = getClassName(ButtonClasses.Base, [
    {
      trueClassName: `${ButtonClasses.Base}--${theme}`,
      condition: !!theme,
    },
  ]);

  return (
    <Button className={buttonClass} variant={variant} onClick={onClick}>
      {label}
    </Button>
  );
};
