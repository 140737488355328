import React, { useMemo } from 'react';
import { Text, TextStyle, TextTheme } from '../../components/text/text.component';
import { Background } from '../../components/background/background.component';
import { Card, CardProps } from '../../components/card/card.component';
import { getPublicRoute, MenuItems, openInNewTab, ScreenWidths, ViewIds } from '../../utils';
import { useMediaQuery } from 'react-responsive';

import './contact.style.scss';

export enum ContactClasses {
  Base = 'contact',
  Title = 'contact_title',
  Cards = 'contact_cards',
  Entry = 'contact_cards_entry',
  Discord = 'contact_cards_entry_discord',
  Email = 'contact_cards_entry_email',
  Background = 'contact_background',
}

const contactCardContent: (CardProps & { image: string })[] = [
  {
    title: 'Discord',
    image: 'discord-logo.svg',
    className: ContactClasses.Discord,
    onClick: () => {
      openInNewTab('https://discord.gg/stdRAHvJjC');
    },
  },
  {
    title: 'Email',
    image: 'email.svg',
    className: ContactClasses.Email,
    children: 'info@puffinpool.com',
    onClick: () => {
      openInNewTab('mailto:info@puffinpool.com');
    },
  },
];

export const Contact = (): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: ScreenWidths.Foldable });
  const locations = isMobile ? ['0', '88.1% 40%'] : ['0', '80% 20%', '25% 60%'];

  const contactCards = useMemo(() => {
    return contactCardContent.map((card, index) => (
      <Card
        key={`contact-card-${index}`}
        className={card.className}
        title={card.title}
        titleTheme={TextTheme.Snowball}
        subtitle={card.subtitle}
        bodyTheme={TextTheme.Snowball}
        src={`${getPublicRoute()}/assets/${card.image}`}
        onClick={card.onClick}
      >
        {card.children}
      </Card>
    ));
  }, []);

  return (
    <div id={ViewIds[MenuItems.Contact]} className={ContactClasses.Base}>
      <Background className={ContactClasses.Background} locations={locations} />
      <div className={ContactClasses.Title}>
        <Text style={TextStyle.Heading} theme={TextTheme.Snowball}>
          Contact Us
        </Text>
      </div>

      <div className={ContactClasses.Cards}>{contactCards}</div>
    </div>
  );
};
