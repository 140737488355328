import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { getPublicRoute } from '../../utils';
import { Card, CardProps } from '../../components/card/card.component';
import './notFound.style.scss';
import { PuffinButton } from '../../components/button/button.component';

export enum NotFoundClasses {
  Base = 'not_found',
  Card = 'not_found_card',
}

export const NotFound = (): JSX.Element => {
  useEffect(() => {
    document.title = `Puffin Pool - Page Not Found`;
    ReactGA.send('pageview');
  }, []);

  const homeButton = useMemo(() => {
    const onClick = () => window.location.assign(window.location.origin);
    return <PuffinButton label="Home" onClick={onClick} />;
  }, []);

  const cardProps: CardProps = {
    className: NotFoundClasses.Card,
    title: '404 - Page Not Found',
    subtitle: 'Whoops!',
    src: `${getPublicRoute()}/assets/404.png`,
    children: homeButton,
  };

  return (
    <div className={NotFoundClasses.Base}>
      <Card {...cardProps} />
    </div>
  );
};
