import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Home } from '../views/home/home.view';
import { NotFound } from '../views/notFound/notFound.view';

const routes: RouteProps[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/*',
    element: <NotFound />,
  },
];

export const PuffinRoutes = routes.map((props: RouteProps, index) => (
  <Route key={`route-${index}`} path={props.path} element={props.element} />
));
