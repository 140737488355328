import * as React from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter, Routes } from 'react-router-dom';
import { PuffinRoutes } from './routes';
import config from './config';

try {
  ReactGA.initialize(config.analytics.id);
} catch (err) {
  console.warn(err);
}

export const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>{PuffinRoutes}</Routes>
    </BrowserRouter>
  );
};

export default React.memo(App);
