import React from 'react';
import { isMobile } from 'react-device-detect';

export const ScreenWidths = {
  Mobile: 479,
  Foldable: 539,
  Tablet: 767,
  Laptop: 1023,
  Desktop: 1199,
  Wide: 1439,
};

export interface BaseProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: (...args) => void;
}

export type ClassNameAdditions = {
  trueClassName: string;
  condition: boolean;
};

export enum MenuItems {
  Roadmap = 'Roadmap',
  FAQ = 'FAQ',
  GetStarted = 'Get Started',
  // Metadata = 'Metadata',
  Contact = 'Contact Us',
}

export const ViewIds: Record<MenuItems, string> = {
  [MenuItems.GetStarted]: 'get-started-id',
  [MenuItems.Roadmap]: 'roadmap-id',
  [MenuItems.FAQ]: 'faq-id',
  // [MenuItems.Metadata]: 'metadata-id',
  [MenuItems.Contact]: 'contact-id',
};

export function getClassName(base: string, additions: ClassNameAdditions[]): string {
  let className = base;

  for (const ad of additions) {
    if (ad.condition) className += ` ${ad.trueClassName}`;
  }

  return className;
}

export function getPublicRoute(): string {
  return process.env.PUBLIC_URL;
}

export function scrollToView(view: MenuItems): void {
  if (isMobile) return scrollToViewMobile(view);

  const element = document.getElementById(ViewIds[view]);
  element?.scrollIntoView();
}

export function scrollToTop(): void {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

/**
 * Scroll to view does not work on mobile
 */
export const scrollToViewMobile = (view: MenuItems): void => {
  const element = document.getElementById(ViewIds[view]);
  const scrollTop = window.pageYOffset || element.scrollTop;

  const finalOffset = element.getBoundingClientRect().top + scrollTop;

  window.parent.scrollTo({
    top: finalOffset,
  });
};

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank');
};
