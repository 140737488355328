import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { PuffinButton, ButtonTheme, ButtonVariant } from '../../components/button/button.component';
import { Text, TextStyle, TextTheme } from '../../components/text/text.component';
import { getPublicRoute, MenuItems, scrollToView } from '../../utils';
import { Toolbar } from '../../components/toolbar/toolbar.component';
import { Roadmap } from '../roadmap/roadmap.view';
import { Starting } from '../starting/starting.view';
import { Contact } from '../contact/contact.view';
import { FrequentQuestions } from '../frequentQuestions/frequentQuestions.view';

import './home.style.scss';

export enum HomeClasses {
  Base = 'home',
  Intro = 'home_intro',
  Splash = 'home_splash',
  SplashSmall = 'home_splash--small',
  SplashMedium = 'home_splash--medium',
  SplashLarge = 'home_splash--large',
  Buttons = 'home_buttons',
  Achievements = 'home_achievements',
  BackgroundShapes = 'home_achievements_shapes',
}

export const Home = (): JSX.Element => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  // const getStartedOnClick = async () => {
  //   ReactGA.gtag({
  //     category: 'site_click',
  //     action: 'get-started-button',
  //     label: 'Get Started (Button)',
  //   });

  //   scrollToView(MenuItems.GetStarted);
  // };

  return (
    <>
      <Toolbar />
      <div className={HomeClasses.Base}>
        <div className={HomeClasses.Intro}>
          <Text style={TextStyle.Heading} theme={TextTheme.Matte}>
            Hop into our pool.
          </Text>
          <Text style={TextStyle.Optimized} theme={TextTheme.Atlantic}>
            Stake your ADA
          </Text>
          <Text theme={TextTheme.Charcoal}>
            Your Cardano doesn&apos;t need to be idle. Let us show you how to passively gain rewards
            on your investment.
          </Text>

          <div className={HomeClasses.Buttons}>
            <PuffinButton label="Get Started" onClick={() => scrollToView(MenuItems.GetStarted)} />
            <PuffinButton
              label="Learn More"
              theme={ButtonTheme.Sunrise}
              variant={ButtonVariant.Outlined}
              onClick={() => scrollToView(MenuItems.FAQ)}
            />
          </div>
        </div>
        <div className={HomeClasses.Splash}>
          <img
            className={HomeClasses.SplashSmall}
            src={`${getPublicRoute()}/assets/beach-scene-sml.png`}
          />
          <img
            className={HomeClasses.SplashMedium}
            src={`${getPublicRoute()}/assets/beach-scene-med.png`}
          />
          <img
            className={HomeClasses.SplashLarge}
            src={`${getPublicRoute()}/assets/beach-scene-lrg.png`}
          />
        </div>
      </div>
      <Roadmap />
      <FrequentQuestions />
      <Starting />
      <Contact />
    </>
  );
};
