import React from 'react';
import { Card, CardLayout, CardProps } from './../../components/card/card.component';
import {
  getPublicRoute,
  MenuItems,
  openInNewTab,
  ScreenWidths,
  scrollToView,
  ViewIds,
} from './../../utils';
import { useMediaQuery } from 'react-responsive';
import './frequentQuestions.style.scss';
import { Expandable, ExpandableProps } from './../../components/expandable/expandable.component';

export enum FrequentQuestionsClasses {
  Base = 'frequent_questions',
  Intro = 'frequent_questions_intro',
  Contact = 'frequent_questions_contact',
  Calculator = 'frequent_questions_calculator',
}

export const FrequentQuestions = (): JSX.Element => {
  const useHybrid = useMediaQuery({ maxWidth: ScreenWidths.Foldable });

  const contactProps = {
    className: FrequentQuestionsClasses.Contact,
    onClick: () => scrollToView(MenuItems.Contact),
  };

  const calculatorProps = {
    className: FrequentQuestionsClasses.Calculator,
    onClick: () => openInNewTab('https://dynamicstrategies.io/crewardcalculator'),
  };

  const introProps: CardProps = {
    className: FrequentQuestionsClasses.Intro,
    title: 'Frequently Asked Questions',
    layout: useHybrid ? CardLayout.Hybrid : CardLayout.Row,
    src: `${getPublicRoute()}/assets/faq.png`,
    children: (
      <>
        Whether you&apos;re a staking veteran, or a first-time swimmer — we want to answer all of
        your questions. If you still have questions after reading this section, feel free to{' '}
        <a {...contactProps}>contact us</a>!
      </>
    ),
  };

  const expandables: ExpandableProps[] = [
    {
      title: 'What is staking?',
      children: `Staking Cardano is an easy way for ADA holders to passively earn rewards on their 
        investment. While staking with Puffin Pool, your holdings are used for validating
        transactions, thus strengthening the Cardano network.`,
    },
    {
      title: 'How much ADA can I earn staking?',
      children: (
        <>
          Rewards vary between stake pools. Users in large pools typically see 4 to 5 percent growth
          annually. Smaller pools see greater variance in rewards, so estimating returns are more
          difficult. Check out this <a {...calculatorProps}>cardano rewards calculator</a> for more
          information.
        </>
      ),
    },
    {
      title: 'What is the minimum amount of ADA required to stake?',
      children: `There is no minimum for staking, but there is a 2 ADA deposit required for delegating
        to a stake pool. This deposit is refunded when you exit the pool.`,
    },
    {
      title: 'Is staking safe? Can I lose my ADA?',
      children: `Staking is completely safe! Your staked ADA never leaves your wallet, so you can 
        transfer funds at any time.`,
    },
  ];

  return (
    <div id={ViewIds[MenuItems.FAQ]} className={FrequentQuestionsClasses.Base}>
      <Card {...introProps} />
      {expandables.map((entry, index) => (
        <Expandable key={`expandable-${index}`} {...entry} />
      ))}
    </div>
  );
};
