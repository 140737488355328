import React, { useMemo } from 'react';
import { Text, TextStyle, TextTheme } from './../../components/text/text.component';
import { Background } from './../../components/background/background.component';
import { Card } from './../../components/card/card.component';
import { ProgressBar, ProgressStyles } from './../../components/progressbar/progressbar.component';
import { getPublicRoute, MenuItems, ScreenWidths, ViewIds } from './../../utils';
import { useMediaQuery } from 'react-responsive';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel, CarouselProps } from 'react-responsive-carousel';

import './roadmap.style.scss';

const currentYear = new Date().getFullYear();

export enum RoadmapClasses {
  Base = 'roadmap',
  Title = 'roadmap_title',
  Cards = 'roadmap_cards',
  Entry = 'roadmap_cards_entry',
  Background = 'roadmap_background',
}

const roadmapCardContent = [
  {
    title: 'Security',
    subtitle: 'DDoS Protection',
    image: 'security.png',
    compact: false,
    achieved: true,
    progressTitle: 'na',
    numerator: 0,
    denominator: 0,
    style: ProgressStyles.Achieved,
  },
  {
    title: 'Monitoring',
    subtitle: 'Dashboard and Alerts',
    image: 'monitoring.png',
    compact: false,
    achieved: true,
    progressTitle: 'na',
    numerator: 0,
    denominator: 0,
    style: ProgressStyles.Achieved,
  },
  {
    title: 'Stake',
    subtitle: '50,000 ADA',
    image: 'stake-goal.png',
    compact: true,
    achieved: false,
    progressTitle: '',
    numerator: 8.2,
    denominator: 50,
    style: ProgressStyles.InProgress,
  },
  {
    title: 'Community',
    subtitle: '100 Delegators',
    image: 'community.png',
    compact: true,
    achieved: false,
    progressTitle: '',
    numerator: 9,
    denominator: 100,
    style: ProgressStyles.InProgress,
  },
  {
    title: 'Coverage',
    subtitle: 'Additional Relays',
    image: 'coverage.png',
    compact: false,
    achieved: true,
    progressTitle: 'na',
    numerator: 0,
    denominator: 0,
    style: ProgressStyles.InProgress,
  },
  {
    title: 'Branding',
    subtitle: 'Puffin Pool Merch',
    image: 'merch.png',
    compact: true,
    achieved: false,
    progressTitle: '',
    numerator: 1,
    denominator: 5,
    style: ProgressStyles.InProgress,
  },
];

export const Roadmap = (): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: ScreenWidths.Foldable });
  const locations = isMobile ? ['0'] : ['0', '30% 75%', '70% 60%', '88.8% 0%'];

  const roadmapCards = useMemo(() => {
    return roadmapCardContent.map((card, index) => (
      <Card
        key={`roadmap-card-${index}`}
        className={RoadmapClasses.Entry}
        title={card.title}
        titleTheme={TextTheme.Snowball}
        subtitle={card.subtitle}
        subtitleTheme={TextTheme.Sky}
        src={`${getPublicRoute()}/assets/${card.image}`}
        bodyTheme={TextTheme.Snowball}
      >
        <ProgressBar
          title={card.progressTitle}
          style={card.style}
          progressNumerator={card.numerator}
          progressDenominator={card.denominator}
        />
      </Card>
    ));
  }, []);

  const carouselProps: Partial<CarouselProps> = {
    className: RoadmapClasses.Cards,
    showArrows: false,
    infiniteLoop: true,
    showStatus: false,
    preventMovementUntilSwipeScrollTolerance: true,
    swipeScrollTolerance: 20,
  };

  return (
    <div id={ViewIds[MenuItems.Roadmap]} className={RoadmapClasses.Base}>
      <Background className={RoadmapClasses.Background} locations={locations} />
      <div className={RoadmapClasses.Title}>
        <Text style={TextStyle.Heading} theme={TextTheme.Snowball}>
          Roadmap
        </Text>
        <Text theme={TextTheme.Snowball}>
          We have lofty goals for {currentYear}. Check out our achievements and see how your stake
          helps strengthen the community!
        </Text>
        {/* <ProgressBar
          title="Milestones"
          compact={false}
          achieved={false}
          progressNumerator={3}
          progressDenominator={4}
        /> */}
      </div>

      {!isMobile ? (
        <div className={RoadmapClasses.Cards}>{roadmapCards}</div>
      ) : (
        <Carousel {...carouselProps}>{roadmapCards}</Carousel>
      )}
    </div>
  );
};
