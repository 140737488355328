import React, { useMemo } from 'react';
import { Text, TextStyle, TextTheme } from '../text/text.component';
import { BaseProps, getClassName } from './../../utils';

import './card.style.scss';

export enum CardClasses {
  Base = 'puf_card',
  Text = 'puf_card_text',
  Title = 'puf_card_title',
  Subtitle = 'puf_card_subtitle',
  Body = 'puf_card_body',
  HybridHeader = 'puf_card_hybrid_header',
  HybridTitle = 'puf_card_hybrid_title',
  HybridBody = 'puf_card_hybrid_body',
}

export enum CardLayout {
  Column = 'column',
  Row = 'row',
  Hybrid = 'hybrid',
}

export interface CardProps extends BaseProps {
  src?: string;
  title: string;
  subtitle?: string;
  titleTheme?: TextTheme;
  subtitleTheme?: TextTheme;
  bodyTheme?: TextTheme;
  layout?: CardLayout;
}

export const Card = (props: CardProps): JSX.Element => {
  const {
    className,
    src,
    title,
    subtitle,
    children,
    titleTheme = TextTheme.Matte,
    subtitleTheme = TextTheme.Atlantic,
    bodyTheme = TextTheme.Charcoal,
    layout = CardLayout.Column,
    onClick = () => null,
  } = props;

  const cardClassName = getClassName(CardClasses.Base, [
    { condition: !!className, trueClassName: className },
    { condition: !!layout, trueClassName: `${CardClasses.Base}--${layout}` },
  ]);

  const titleBlock = useMemo(
    () => (
      <Text className={CardClasses.Title} style={TextStyle.Heading} theme={titleTheme}>
        {title}
      </Text>
    ),
    [title, titleTheme],
  );

  const subtitleBlock = useMemo(() => {
    return (
      subtitle && (
        <Text className={CardClasses.Subtitle} style={TextStyle.Subheading} theme={subtitleTheme}>
          {subtitle}
        </Text>
      )
    );
  }, [subtitle, subtitleTheme]);

  const bodyBlock = useMemo(() => {
    return (
      children && (
        <div className={layout === CardLayout.Hybrid ? CardClasses.HybridBody : CardClasses.Body}>
          {
            <Text className={CardClasses.Body} theme={bodyTheme}>
              {children}
            </Text>
          }
        </div>
      )
    );
  }, [children, bodyTheme, layout]);

  const imageBlock = useMemo(() => {
    return src && <img src={src} />;
  }, [src]);

  const standardLayout = useMemo(
    () => (
      <>
        {imageBlock}
        <div className={CardClasses.Text}>
          {titleBlock}
          {subtitleBlock}
          {bodyBlock}
        </div>
      </>
    ),
    [bodyBlock, imageBlock, subtitleBlock, titleBlock],
  );

  // in order to get the look of a specific layout we need to shake up div elements a bit
  const hybridLayout = useMemo(
    () => (
      <>
        <div className={CardClasses.HybridHeader}>
          {imageBlock}
          <div className={CardClasses.HybridTitle}>
            {titleBlock}
            {subtitleBlock}
          </div>
        </div>
        {bodyBlock}
      </>
    ),
    [bodyBlock, imageBlock, subtitleBlock, titleBlock],
  );

  return (
    <div className={cardClassName} onClick={onClick}>
      {layout === CardLayout.Hybrid ? hybridLayout : standardLayout}
    </div>
  );
};
