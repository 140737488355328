import React from 'react';
import { BaseProps, getClassName, getPublicRoute } from '../../utils';
import './logo.style.scss';

export enum LogoClasses {
  Base = 'logo',
  Icon = 'logo_icon',
  Text = 'logo_text',
  Pool = 'logo_text_pool',
}

export enum LogoStyle {
  Normal = 'Normal',
  Stacked = 'Stacked',
}

export interface LogoProps extends BaseProps {
  style?: LogoStyle;
  onClick?: () => void;
}

export const Logo = (props: LogoProps): JSX.Element => {
  const { style, className, onClick = () => null } = props;

  const baseClassName = getClassName(LogoClasses.Base, [
    {
      condition: style === LogoStyle.Stacked,
      trueClassName: `${LogoClasses.Base}--stacked`,
    },
    {
      condition: !!className,
      trueClassName: className,
    },
  ]);

  return (
    <div className={baseClassName} onClick={onClick}>
      <img className={LogoClasses.Icon} src={`${getPublicRoute()}/logo.svg`} />
      <div className={LogoClasses.Text}>
        <span>Puffin </span>
        <span className={LogoClasses.Pool}>Pool</span>
      </div>
    </div>
  );
};
