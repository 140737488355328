// Created by: Joel Klemens - March 27, 2022

import React, { useMemo } from 'react';
import { Text, TextStyle, TextTheme } from '../text/text.component';
import { BaseProps, getClassName, getPublicRoute } from './../../utils';

import './progressbar.style.scss';

export enum ProgressBarClasses {
  Base = 'puf_progressbar',
  Full = 'puf_progressbar_full',
  Small = 'puf_progressbar_small',
  Achieved = 'puf_progressbar_achieved',
  BarOuter = 'puf_progressbar_bar_outer',
  BarInner = 'puf_progressbar_bar_inner',
  BarSmall = 'puf_progressbar_bar_small',
  Checkmark = 'puf_progressbar_checkmark',
  ProgressValue = 'puf_progressbar_bar_value',
  Title = 'puf_progressbar_title',
  InProgress = 'puf_progressbar_title--progress',
}

export enum ProgressStyles {
  Full = 'full',
  Compact = 'compact',
  Achieved = 'achieved',
  InProgress = 'in-progress',
}

export interface ProgressProps extends BaseProps {
  title: string;
  style?: ProgressStyles;
  compact?: boolean;
  achieved?: boolean;
  titleTheme?: TextTheme;
  bodyTheme?: TextTheme;
  progressNumerator?: number;
  progressDenominator?: number;
  progressValue?: number;
}

export const ProgressBar = (props: ProgressProps): JSX.Element => {
  const {
    className,
    title,
    style = ProgressStyles.Compact,
    progressNumerator,
    progressDenominator,
    progressValue = (progressNumerator / progressDenominator) * 100,
    titleTheme = TextTheme.Snowball,
  } = props;

  const progressClassName = getClassName(ProgressBarClasses.Base, [
    { condition: !!className, trueClassName: className },
  ]);

  const titleClassName = getClassName(ProgressBarClasses.Title, [
    {
      condition: style === ProgressStyles.InProgress,
      trueClassName: ProgressBarClasses.InProgress,
    },
  ]);

  const progressBarMemo = useMemo(
    () => (
      <div className={progressClassName}>
        {style === ProgressStyles.Achieved || style === ProgressStyles.InProgress ? (
          <div className={ProgressBarClasses.Achieved}>
            <Text className={titleClassName} style={TextStyle.Body} theme={titleTheme}>
              {style === ProgressStyles.Achieved ? 'Achieved' : 'In progress'}
            </Text>
            {style === ProgressStyles.Achieved && (
              <img
                className={ProgressBarClasses.Checkmark}
                src={`${getPublicRoute()}/assets/checkmark.png`}
              />
            )}
          </div>
        ) : style === ProgressStyles.Compact ? (
          <div className={ProgressBarClasses.Small}>
            <Text className={ProgressBarClasses.Title} style={TextStyle.Body} theme={titleTheme}>
              {`${progressNumerator}/${progressDenominator} ${title}`}
            </Text>
            <div className={ProgressBarClasses.BarOuter}>
              <div
                className={ProgressBarClasses.BarInner}
                style={{ width: progressValue + '%' }}
              ></div>
            </div>
          </div>
        ) : (
          <div className={ProgressBarClasses.Full}>
            <Text className={ProgressBarClasses.Title} style={TextStyle.Heading} theme={titleTheme}>
              {`${progressNumerator}/${progressDenominator} ${title}`}
            </Text>
            <div className={ProgressBarClasses.BarOuter}>
              <div
                className={ProgressBarClasses.BarInner}
                style={{ width: progressValue + '%' }}
              ></div>
            </div>
          </div>
        )}
      </div>
    ),
    [
      titleClassName,
      progressClassName,
      progressDenominator,
      progressNumerator,
      progressValue,
      style,
      title,
      titleTheme,
    ],
  );
  return progressBarMemo;
};
