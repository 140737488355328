import React from 'react';
import { BaseProps, getClassName, getPublicRoute } from './../../utils';
import './background.style.scss';

export enum BackgroundClassNames {
  Base = 'puf_background',
  Decal = 'puf_background_decal',
}

export interface BackgroundProps extends BaseProps {
  locations: string[];
}

export const Background = (props: BackgroundProps): JSX.Element => {
  const { className, locations } = props;
  const backgroundClass = getClassName(BackgroundClassNames.Base, [
    {
      condition: !!className,
      trueClassName: className,
    },
  ]);

  return (
    <div className={backgroundClass}>
      {locations.map((inset, index) => (
        <img
          style={{ inset }}
          key={`decal-${index}`}
          className={BackgroundClassNames.Decal}
          src={`${getPublicRoute()}/assets/shapes.png`}
        />
      ))}
    </div>
  );
};
